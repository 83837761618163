import React, {useState, useEffect} from 'react';
import { TextField, Grid, Typography, Button } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import image from './../assets/7849.jpg';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Menu from '../components/menu.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import img from '../assets/4140045.jpg';
import Rodape from '../components/rodape.js';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import qs from 'qs';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ValidarCadastro () {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [senha, setSenha] = useState();
    const [confirmarSenha, setConfirmarSenha] = useState();
    const [email, setEmail] = useState();

    const [openSnack, setOpenSnack] = useState(false);
    const [messageSnack, setMessageSnack] = useState("");
    const [typeSnack, setTypeSnack] = useState("success");

    useEffect(() => {

        let url = window.location.href;
        let url_token = url.split("=")[1];

        if(!url_token){
            window.location.href="/";
        }

        let token = url_token.split("&")[0];

        if(!token){
            window.location.href="/";
        }

    });

    const handleValidar = () => {

        setLoading(true);

        if(!email || !senha || !confirmarSenha){

            setLoading(false);
            setOpenSnack(true);
            setMessageSnack("Digite seu email e senha");
            setTypeSnack("error");

        }else{

            if(senha != confirmarSenha){

                setLoading(false);
                setOpenSnack(true);
                setMessageSnack("As senhas devem ser iguais");
                setTypeSnack("error");

            }else{

                let url = window.location.href;
                let url_token = url.split("=")[1];
                let token = url_token.split("&")[0];

                api.post('/auth/validar', {
                    token: token,
                    senha: senha,
                    email: email
                })
                .then(res => {

                    setLoading(false);
                    setOpenSnack(true);
                    setMessageSnack("Conta validada com sucesso");
                    setTypeSnack("success");

                    if(window.location.hostname === "localhost"){
                        window.location.href = "http://localhost:3001?token="+res.data.token;
                    }else{
                        window.location.href = "https://app.gryb.com.br?token="+res.data.token;
                    }

                })
                .catch(err => {

                    setLoading(false);
                    setOpenSnack(true);
                    setMessageSnack("Erro ao validar a conta");
                    setTypeSnack("error");

                })

            }

        }

    }

    return (
        <>
            <div className={classes.root}>

                <Menu register={true} />
                
                <Container style={{marginTop: '50px', marginBottom: '50px'}}>

                    <Grid spacing={3} container>
                        <Grid md={4} xs={1}></Grid>
                        <Grid md={4} xs={10} style={{display: 'flex', justifyContent: 'center'}}>
                            
                            <div style={{width: '100%'}}>

                                <center>
                                    <img src={img} style={{width: '300px'}} />

                                    <Typography variant="h6" className={classes.title}>
                                        Cadastre sua senha
                                    </Typography>

                                    <Typography variant="body1" style={{color: '#707072'}}>
                                        Vamos validar seu cadastro
                                    </Typography>
                                </center>

                                <br></br>
                                <br></br>

                                <TextField onChange={(e) => {setEmail(e.target.value)}} value={email} variant="outlined" label="e-mail" type="text" size="small" style={{width: '100%'}} />

                                <br></br>
                                <br></br>

                                <TextField onChange={(e) => {setSenha(e.target.value)}} value={senha} variant="outlined" label="senha" type="password" size="small" style={{width: '100%'}} />

                                <br></br>
                                <br></br>

                                <TextField onChange={(e) => {setConfirmarSenha(e.target.value)}} value={confirmarSenha} variant="outlined" label="confirmar senha" type="password" size="small" style={{width: '100%'}} />

                                <br></br>
                                <br></br>

                                <Button onClick={handleValidar} variant="contained" style={{backgroundColor: '#06B4B8', textTransform: 'none', color: '#FFF', width: '100%'}}>
                                    {loading ?
                                        <CircularProgress size={20} style={{color: '#FFFFFF'}} />
                                    :
                                        "continuar"
                                    }
                                </Button>
                            </div>

                        </Grid>
                        <Grid md={4} xs={1}></Grid>
                    </Grid>

                </Container>

                <Rodape style={{marginTop: '600px'}} />

                <Snackbar open={openSnack} autoHideDuration={6000} onClose={(e) => {setOpenSnack(false)}}>
                    <Alert onClose={(e) => {setOpenSnack(false)}} severity={typeSnack}>
                    {messageSnack}
                    </Alert>
                </Snackbar>

            </div>
        </>
    );

}

export default ValidarCadastro;