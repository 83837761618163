import React, {useState, useEffect} from 'react';
import { TextField, Grid, Typography, Button } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import image from './../assets/7849.jpg';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Menu from '../components/menu.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import img from '../assets/4140045.jpg';
import error from '../assets/3024051.png';
import Rodape from '../components/rodape.js';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import qs from 'qs';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RecuperarSenha = props => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [senha, setSenha] = useState();
    const [confirmarSenha, setConfirmarSenha] = useState();
    const [email, setEmail] = useState();

    const [openSnack, setOpenSnack] = useState(false);
    const [messageSnack, setMessageSnack] = useState("");
    const [typeSnack, setTypeSnack] = useState("success");

    const [token, setToken] = useState();
    const [validoToken, setValidoToken] = useState(true);

    useEffect(() => {

        if(!props.match.params.id){

            window.location.href="/";

        }else{

            var pathArray = window.location.pathname.split('recuperar-senha/');

            setToken(pathArray[1]);

            api.get(`/auth/validade-token?token=${pathArray[1]}`)
            .then(res => {

                if(res.data.status === "1"){
                    setValidoToken(false);
                }else{
                    setValidoToken(true);
                }

            })
            .catch(err => {

                /*setLoading(false);
                setOpenSnack(true);
                setMessageSnack("Erro ao valdiar token");
                setTypeSnack("error");*/

                setValidoToken(false);

            })

            

        }

    }, [0]);

    const handleRecuperarSenha = () => {

        setLoading(true);

        if(!email || !senha || !confirmarSenha){

            setLoading(false);
            setOpenSnack(true);
            setMessageSnack("Preencha todos os campos");
            setTypeSnack("error");

        }else{

            if(senha != confirmarSenha){

                setLoading(false);
                setOpenSnack(true);
                setMessageSnack("As senhas devem ser iguais");
                setTypeSnack("error");

            }else{

                api.post('/auth/nova-senha', {
                    token: token,
                    senha: senha,
                    email: email
                })
                .then(res => {

                    setLoading(false);
                    setOpenSnack(true);
                    setMessageSnack("Senha alterada com sucesso");
                    setTypeSnack("success");

                    setTimeout(function(){

                        if(window.location.hostname === "localhost"){
                            window.location.href = "http://localhost:3001?token="+res.data.token;
                        }else{
                            window.location.href = "https://app.gryb.com.br?token="+res.data.token;
                        }

                    }, 1000);

                })
                .catch(err => {

                    setLoading(false);
                    setOpenSnack(true);
                    setMessageSnack("Erro ao alterar senha");
                    setTypeSnack("error");

                })

            }

        }

    }

    return (
        <>
            <div className={classes.root}>

                <Menu register={true} />
                
                <Container style={{marginTop: '50px', marginBottom: '50px'}}>

                    <Grid spacing={3} container>
                        <Grid md={4} xs={1}></Grid>
                        <Grid md={4} xs={10} style={{display: 'flex', justifyContent: 'center'}}>

                            {validoToken ? 

                                <>
                            
                                    <div style={{width: '100%'}}>

                                        <center>
                                            <img src={img} style={{width: '300px'}} />

                                            <Typography variant="h6" className={classes.title}>
                                                Cadastre sua nova senha
                                            </Typography>
                                        </center>

                                        <br></br>
                                        <br></br>

                                        <TextField onChange={(e) => {setEmail(e.target.value)}} value={email} variant="outlined" label="e-mail" type="text" size="small" style={{width: '100%'}} />

                                        <br></br>
                                        <br></br>

                                        <TextField onChange={(e) => {setSenha(e.target.value)}} value={senha} variant="outlined" label="senha" type="password" size="small" style={{width: '100%'}} />

                                        <br></br>
                                        <br></br>

                                        <TextField onChange={(e) => {setConfirmarSenha(e.target.value)}} value={confirmarSenha} variant="outlined" label="confirmar senha" type="password" size="small" style={{width: '100%'}} />

                                        <br></br>
                                        <br></br>

                                        <Button onClick={handleRecuperarSenha} variant="contained" style={{backgroundColor: '#06B4B8', textTransform: 'none', color: '#FFF', width: '100%'}}>
                                            {loading ?
                                                <CircularProgress size={20} style={{color: '#FFFFFF'}} />
                                            :
                                                "recuperar"
                                            }
                                        </Button>
                                    </div>

                                </>

                            :

                                <>

                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '80vh'}}>

                                        <div>
                                            <center>
                                                <img src={error} style={{width: '300px'}} />
                                                <br></br>
                                                <br></br>
                                                <Typography variant="h6" className={classes.title}>
                                                    Token inválido
                                                </Typography>
                                            </center>
                                        </div>

                                    </div>

                                </>

                            }

                        </Grid>
                        <Grid md={4} xs={1}></Grid>
                    </Grid>

                </Container>

                {validoToken && (
                    <Rodape style={{marginTop: '600px'}} />
                )}

                <Snackbar open={openSnack} autoHideDuration={6000} onClose={(e) => {setOpenSnack(false)}}>
                    <Alert onClose={(e) => {setOpenSnack(false)}} severity={typeSnack}>
                    {messageSnack}
                    </Alert>
                </Snackbar>

            </div>
        </>
    );

}

export default RecuperarSenha;