import React, {useState, useEffect} from 'react';
import { TextField, Grid, Typography, Button } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import logo from './../assets/logo.png';
import image from './../assets/13018.png';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      weigth: '100vh',
      backgroundColor: '#FFFFFF',
      '@media (max-width: 600px)': {
        minHeight: '80vh'
      }
    },
    textField: {
        width: '70%', 
        marginLeft: '15%',
        '@media (max-width: 600px)': {
            width: '100%',
            marginLeft: '0%'
        }
    },
    button: {
        width: '70%', 
        marginLeft: '15%', 
        backgroundColor: '#06B4B8', 
        color: '#FFFFFF', 
        textTransform: 'capitalize',
        '@media (max-width: 600px)': {
            width: '100%',
            marginLeft: '0%'
        }
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Login () {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [loadingRecuperar, setLoadingRecuperar] = useState(false);
    
    const [openSnack, setOpenSnack] = useState(false);
    const [messageSnack, setMessageSnack] = useState("");
    const [typeSnack, setTypeSnack] = useState("success");

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");

    const [esqueceuSenha, setEsqueceuSenha] = useState(false);

    useEffect(() => {})

    const handleLogin = () => {

        if(email && senha){

            setLoading(true);

            api.post('/auth/login', {
                email: email,
                password: senha
            })
            .then(res => {
                setLoading(false);
                setOpenSnack(true);
                setTypeSnack("success");
                setMessageSnack("Login realizado! Aguarde ...");
                if(window.location.hostname === "localhost"){
                    window.location.href = "http://localhost:3001?token="+res.data.token;
                }else{
                    window.location.href = "https://app.gryb.com.br?token="+res.data.token;
                }
            })
            .catch(err => {
                setLoading(false);
                setOpenSnack(true);
                setTypeSnack("error");
                setMessageSnack("Dados inválidos");
            })

        }else{

            setOpenSnack(true);
            setTypeSnack("error");
            setMessageSnack("Preencha todos os dados!");

        }

    }

    const handleRecuperarSenha = () => {

        if(email){

            setLoadingRecuperar(true);

            api.post('/auth/recover-password', {
                email: email
            })
            .then(res => {
                setLoadingRecuperar(false);
                setOpenSnack(true);
                setTypeSnack("success");
                setMessageSnack("Verifique a caixa de entrada do seu e-mail!");
                setEsqueceuSenha(false);
            })
            .catch(err => {
                setLoadingRecuperar(false);
                setOpenSnack(true);
                setTypeSnack("error");
                setMessageSnack("Dados inválidos");
            })

        }else{

            setOpenSnack(true);
            setTypeSnack("error");
            setMessageSnack("Preencha o e-mail!");

        }

    }

    return (
        <>
            <div className={classes.root}>
                <div style={{width: '80%'}}>
                    <Grid spacing={3} container style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Grid item md={6} xs={12}>
                            <center>
                                <img src={image} style={{width: '100%'}} />
                            </center>
                        </Grid>
                        <Grid item md={6} xs={12}>

                            {!esqueceuSenha ? 
                                <>
                                    <Typography variant="h6" style={{textAlign: 'center'}}>
                                        Faça seu login
                                    </Typography>
                                    <Typography variant="body1" style={{color: '#707072', textAlign: 'center'}}>
                                        ou
                                        <Link to="/register" style={{textDecoration: 'none'}}>
                                            <span style={{color: '#06B4B8'}}> cadastre-se</span>
                                        </Link>
                                    </Typography>
                                    <br></br>
                                    <br></br>
                                    <TextField onKeyUp={(e) => {
                                        if(e.keyCode === 13){
                                            handleLogin();
                                        }
                                    }} onChange={(e) => {setEmail(e.target.value)}} id="outlined-basic" size="small" label="E-mail" variant="outlined" className={classes.textField} />
                                    <br></br>
                                    <br></br>
                                    <TextField onKeyUp={(e) => {
                                        if(e.keyCode === 13){
                                            handleLogin();
                                        }
                                    }} onChange={(e) => {setSenha(e.target.value)}} className={classes.textField} id="outlined-basic" size="small" label="Senha" type="password" variant="outlined" />
                                    <br></br>
                                    <br></br>
                                    <Button onClick={handleLogin} variant="contained" className={classes.button}>
                                        {loading ?
                                            <CircularProgress size={20} style={{color: '#FFFFFF'}} />
                                        :
                                            "Logar"
                                        }
                                    </Button>
                                    <br></br>
                                    <br></br>
                                    <Typography onClick={() => {setEsqueceuSenha(true)}} variant="body1" style={{color: '#707072', textAlign: 'center', cursor: 'pointer'}}>
                                        Esqueceu sua senha?
                                    </Typography>
                                </>
                            :
                                <>
                                    <Typography variant="h6" style={{textAlign: 'center'}}>
                                        Esqueceu sua senha
                                    </Typography>
                                    <Typography variant="body1" style={{color: '#707072', textAlign: 'center'}}>
                                        Insira seu e-mail para recuperação
                                    </Typography>
                                    <br></br>
                                    <br></br>
                                    <TextField onKeyUp={(e) => {
                                        if(e.keyCode === 13){
                                            handleRecuperarSenha();
                                        }
                                    }} onChange={(e) => {setEmail(e.target.value)}} id="outlined-basic" size="small" label="E-mail" variant="outlined" className={classes.textField} />
                                    <br></br>
                                    <br></br>
                                    <Button onClick={handleRecuperarSenha} variant="contained" className={classes.button}>
                                        {loadingRecuperar ?
                                            <CircularProgress size={20} style={{color: '#FFFFFF'}} />
                                        :
                                            "Recuperar"
                                        }
                                    </Button>
                                    <br></br>
                                    <br></br>
                                    <Typography onClick={() => {setEsqueceuSenha(false)}} variant="body1" style={{color: '#06B4B8', textAlign: 'center', cursor: 'pointer'}}>
                                        Realizar login
                                    </Typography>
                                </>
                            }

                            <Snackbar open={openSnack} autoHideDuration={6000} onClose={(e) => {setOpenSnack(false)}}>
                                <Alert onClose={(e) => {setOpenSnack(false)}} severity={typeSnack}>
                                    {messageSnack}
                                </Alert>
                            </Snackbar>

                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );

}

export default Login;