import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
  root: {},
  desktop: {
    ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
      display: 'none'
    }
  },
  mobile: {
    ['@media (min-width: 780px)']: { // eslint-disable-line no-useless-computed-key
      display: 'none'
    }
  }
}));

const Rodape = props => {

  const classes = useStyles();
  const [date, setDate] = useState(new Date());

  return (  
    <>
      <div className={classes.desktop}>
        <Grid container style={{backgroundColor: '#FFF', paddingBottom: '30px'}}>
          <Grid md={12} style={{textAlign: 'center'}}>
            <Typography variant="body1" style={{color: '#707072'}}>© {date.getFullYear()} Gryb</Typography>
          </Grid>
          <Grid md={12} style={{textAlign: 'center', marginTop: '10px'}}>
            <Tooltip title="Em breve">
              <IconButton size="small" aria-label="redes_sociais" style={{backgroundColor: '#06B4B8', marginRight: '10px'}}>
                <FacebookIcon style={{color: '#FFF', padding: '4px', fontSize: '17px'}} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Em breve">
              <IconButton size="small" aria-label="redes_sociais" style={{backgroundColor: '#06B4B8'}}>
                <InstagramIcon style={{color: '#FFF', padding: '4px', fontSize: '17px'}} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
      <div className={classes.mobile}>
        <Grid container style={{backgroundColor: '#FFF', paddingBottom: '30px'}}>
          <Grid md={12} xs={12}>
            <center>
              <Typography variant="body1" style={{color: '#707072', marginBottom: '10px'}}>© {date.getFullYear()} Gryb</Typography>
              <Tooltip title="Em breve">
              <IconButton size="small" aria-label="redes_sociais" style={{backgroundColor: '#06B4B8', marginRight: '10px'}}>
                <FacebookIcon style={{color: '#FFF', padding: '4px', fontSize: '17px'}} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Em breve">
              <IconButton size="small" aria-label="redes_sociais" style={{backgroundColor: '#06B4B8'}}>
                <InstagramIcon style={{color: '#FFF', padding: '4px', fontSize: '17px'}} />
              </IconButton>
            </Tooltip>
            </center>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Rodape;
