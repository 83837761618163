import React, {useState, useEffect} from 'react';
import logo from './assets/logo.png';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import capa from './assets/4097557.png';
import Grid from '@material-ui/core/Grid';
import capa2 from './assets/3255469.png';
import {Link} from "react-router-dom";
import Menu from './components/menu.js';
import Rodape from './components/rodape.js';
import mockup from './assets/mockup.png';
import Paper from '@material-ui/core/Paper';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionThreeCapa: {
    width: '70%',
    '@media (max-width: 600px)': {
      width: '100%',
    }
  },
  sectionOne: {
    // marginTop: '160px', 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
    // '@media (max-width: 600px)': {
    //   marginTop: '80px', 
    // }
  },
  sectionOneCapa: {
    width: '100%',
    '@media (max-width: 600px)': {
      width: '100%', 
      marginTop: '40px'
    }
  },
  sectionTwo: {
    /*backgroundColor: '#06B4B8'
    backgroundColor: '#F5F5F5',*/
    marginTop: '140px', 
    paddingTop: '70px', 
    paddingBottom: '70px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginTop: '80px'
    }
  },
  sectionThreeDesktop: {
    paddingTop: '100px', 
    paddingBottom: '100px', 
    display: 'flex', 
    alignItems: 'center',
    '@media (max-width: 600px)': {
      display: 'none',
    }
  },
  sectionThreeMobile: {
    paddingTop: '20px', 
    paddingBottom: '100px', 
    '@media (min-width: 601px)': {
      display: 'none',
    }
  },
  plataforma: {
    '@media (max-width: 600px)': {
      marginTop: '40px'
    }
  },
  botaoCookie: {
    backgroundColor: '#000', 
    textTransform: 'none', 
    color: '#FFF', 
    width: '100%', 
    '@media (max-width: 600px)': {
      marginTop: '10px'
    }
  }
}));

function App() {

  const classes = useStyles();
  const [cookie, setCookie] = useState(false);

  useEffect(() => {

    if(localStorage.getItem('cookie') && localStorage.getItem('cookie') === "true"){
      setCookie(true);
    }else{
      setCookie(false);
    }

  }, [])

  return (  
    <div className="App">
      {process.env.REACT_APP_MODE != 'preview' ?
        <header className="App-header">

          <Menu />

          <Grid className={classes.sectionOne} container>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={4} xs={10}>
              <Typography variant="h4" style={{color: 'black'}}>
                Grow your business
              </Typography>
              <Typography variant="body1" style={{color: 'black', marginTop: '20px', color: 'gray'}}>
                Queremos ajudar empresas, oferencendo análise digitalizada e assessoria para tornar o 
                seu negócio eficiente, minimizando perdas e prejuízos.
                <br></br><br></br>
                Queremos ajudar a organizar e tornar sua empresa cada vez mais rentável.
              </Typography>
              <Link to="/register" style={{textDecoration: 'none'}}>
                <Button variant="contained" color="primary" style={{
                  textTransform: 'initial',
                  backgroundColor: 'black',
                  borderRadius: '50px',
                  marginTop: '20px'
                }}>
                  Cadastre sua empresa
                </Button>
              </Link>
            </Grid>
            <Grid item md={1} xs={0}></Grid>
            <Grid item md={5} xs={12}>
              <center>
                <img alt="Gryb" src={capa} className={classes.sectionOneCapa} />
              </center>
            </Grid>
            <Grid item md={1} xs={1}></Grid>
          </Grid>

          <Grid container className={classes.sectionTwo}>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={5} xs={10}>
              <center>
                <img alt="Gryb" src={mockup} style={{maxWidth: '70%'}} />
              </center>
            </Grid>
            <Grid item md={5} xs={12}>
              <div style={{backgroundColor: '#F5F5F5', padding: '30px', borderRadius: '30px'}} className={classes.plataforma}>
                <Typography variant="h5" style={{color: '#06B4B8'}}>
                  Sobre a plataforma
                </Typography>
                <Typography variant="body1" style={{color: 'black', marginTop: '20px', color: 'gray'}}>
                  No Brasil, 90% das empresas são PME's e por isso apresentam fragilidades e pontos fracos. Muitas não possuem 
                  condições de investir em algum suporte para crescimento e é aí que entramos em ação. 
                  <br></br><br></br>
                  A startup analisará de forma digitalizada sua empresa e mostrará onde a operação é deficitária, através da informação de dados e do segmento de mercado.
                  <br></br><br></br>
                  Crises são ciclícas e não queremos que as empresas brasileiras fiquem desprotegidas.
                </Typography>
              </div>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>

          <Grid container className={classes.sectionThreeDesktop}>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={4} xs={10}>
              <Typography variant="h5" style={{color: '#06B4B8'}}>
                Como funciona?
              </Typography>
              <Typography variant="body1" style={{marginTop: '20px', color: 'gray'}}>
                A plataforma consiste em inteligência artifical, ou seja, ela aprende e consegue gerar dados cada vez mais precisos.
                <br></br><br></br>
                Todos os dados são criptografados e o algoritmo trabalha de forma com que somente a plataforma consiga ter acesssos às informações para gerar relatórios e indicadores.
              </Typography>
              <Typography variant="body1" style={{color: '#06B4B8', marginTop: '20px'}}>
                Como usar?
              </Typography>
              <Typography variant="body1" style={{marginTop: '20px', color: 'gray'}}>
                Basta inserir o CNPJ e informas dados básicos do fluxo de caixa da empresa, fazendo o upload de planilhas e/ou extratos de contas bancárias.
                <br></br><br></br>
                Em posse destes dados e juntamente com os demais, extraídos de fontes como e-CNPJ, o algortimo gera os indicadores e relatórios, informando o que deve ser feito
                e alertando sobre possíveis falhas de operação. 
              </Typography>
              <Typography variant="body1" style={{color: '#06B4B8', marginTop: '20px'}}>
                Queremos ajudar a sua empresa a crescer
              </Typography>
            </Grid>
            <Grid item md={1} xs={0}></Grid>
            <Grid item md={5} xs={12}>
              <img alt="Gryb" src={capa2} className={classes.sectionThreeCapa} style={{float: 'right'}} />
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>

          <div className={classes.sectionThreeMobile}>
            <Grid container>
              <Grid item md={1} xs={1}></Grid>
              <Grid item md={5} xs={10}>
                <img alt="Gryb" src={capa2} className={classes.sectionThreeCapa} />
              </Grid>
              <Grid item md={1} xs={1}></Grid>
            </Grid>
            <Grid container style={{marginTop: '30px'}}>
              <Grid item md={5} xs={1}></Grid>
              <Grid item md={5} xs={10}>
                <Typography variant="h5" style={{color: '#06B4B8'}}>
                  Como funciona?
                </Typography>
                <Typography variant="body1" style={{marginTop: '20px', color: 'gray'}}>
                  A plataforma consiste em inteligência artifical, ou seja, ela aprende e consegue gerar dados cada vez mais precisos.
                  <br></br><br></br>
                  Todos os dados são criptografados e o algoritmo trabalha de forma que somente ela consegue ter acesso as informações para gerar relatórios e kpi`s.
                </Typography>
                <Typography variant="body1" style={{color: '#06B4B8', marginTop: '20px'}}>
                  Como usar?
                </Typography>
                <Typography variant="body1" style={{marginTop: '20px', color: 'gray'}}>
                  Basta inserir o CNPJ e informas dados básicos do fluxo de caixa da empresa, fazendo o upload de planilhas e/ou extratos de contas bancárias.
                  <br></br><br></br>
                  Em posse destes dados e juntamente com os demais extraidos de fontes como e-CNPJ, o algortimo gera os indicadores e relatórios informando o que deve ser feito
                  <br></br>
                  e alertando sobre possíveis falhas de operações. 
                </Typography>
                <Typography variant="body1" style={{color: '#06B4B8', marginTop: '20px'}}>
                  Queremos ajudar a sua empresa a crescer
                </Typography>
              </Grid>
              <Grid item md={1} xs={1}></Grid>
            </Grid>
          </div>

          <Rodape />

          {/** Aviso uso de cookies */}

          {!cookie && (
            <Paper elevation={3} style={{position: 'fixed', bottom: 20, padding: '20px', left: '14%', right: '14%'}}>
              <Grid container>
                <Grid md={11} xs={12} style={{display: 'flex', alignItems: 'center'}}>
                  <Typography variant="body2">Utilizamos cookies essenciais e, ao continuar navegando, você concorda com estas condições.</Typography>
                </Grid>
                <Grid md={1} xs={12}>
                <Button onClick={() => {setCookie(true); localStorage.setItem('cookie', 'true')}} className={classes.botaoCookie}>Entendi</Button>
                </Grid>
              </Grid>
            </Paper>
          )}
          
        </header>
      :
        <Grid className={classes.sectionOne} container>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={4} xs={10}>
            <Typography variant="h4" style={{ fontWeight: 'lighter' }}>
              GR<span style={{ color: 'rgb(6, 180, 184)' }}>Y</span>B
            </Typography>
            <Typography variant="body1" style={{color: 'black', marginTop: '20px', color: 'gray'}}>
              Queremos ajudar empresas, oferencendo análise digitalizada e assessoria para tornar o 
              seu negócio eficiente, minimizando perdas e prejuízos.
              <br></br><br></br>
              Queremos ajudar a organizar e tornar sua empresa cada vez mais rentável.
            </Typography>
            <br></br>
            <Typography>Em breve será possível usar nossa plataforma!</Typography>
            <Hidden smUp>
              <img alt="Gryb" src={capa} className={classes.sectionOneCapa} />
            </Hidden>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Hidden smDown>
            <Grid item md={5} xs={12}>
              <center>
                <img alt="Gryb" src={capa} className={classes.sectionOneCapa} />
              </center>
            </Grid>
            <Grid item md={1} xs={1}></Grid>
          </Hidden>
        </Grid>
      }
    </div>
  );
}

export default App;
