import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Register from './pages/register.js';
import Login from './pages/login.js';
import EmBreve from './pages/em-breve.js';
import ValidarCadastro from './pages/validar-cadastro.js';
import RecuperarSenha from './pages/recuperar-senha.js';
import Onboarding from './pages/onboarding/onboarding.js';
import OnboardingOfx from './pages/onboarding/onboarding-ofx.js';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={App} />
      <Route path="/register" exact={true} component={Register} />
      <Route path="/validar-cadastro" exact={true} component={ValidarCadastro} />
      <Route path="/login" exact={true} component={Login} />
      <Route path="/em-breve" exact={true} component={EmBreve} />
      <Route path="/onboarding" exact={true} component={Onboarding} />
      <Route path="/onboarding/ofx" exact={true} component={OnboardingOfx} />
      <Route path="/recuperar-senha/:id" component={RecuperarSenha} />
    </Switch>
  </ BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
