import React, {useState, useEffect} from 'react';
import { TextField, Grid, Typography, Button } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      weigth: '100vh',
      backgroundColor: '#FFFFFF'
    }
}));

function OnboardingOfx () {

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>

            </div>
        </>
    );

}

export default OnboardingOfx;