import React from 'react';
import logo from './../assets/logo.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { fade, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  cadastre: {
    textDecoration: 'none', 
    color: '#000',
    ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
        display: 'none'
    }
  }
}));

const Menu = props => {

  const classes = useStyles();

  return (  
    <>
        <AppBar position="static" style={{backgroundColor: '#FFFFFF', color: 'black'}}>
            <Toolbar>
                <a href="/">
                    <img src={logo} style={{width: '100px'}} />
                </a>
                <div className={classes.grow} />
                <div className={classes.search} style={{display: 'contents'}}>
                    {!props.register && (
                        <>
                            <Link className={classes.cadastre} to="register">
                                <Typography>cadastre sua empresa</Typography>
                            </Link>
                            &nbsp;
                            &nbsp;
                        </>
                    )}
                    <Link to="/login" style={{textDecoration: 'none'}}>
                        <Button variant="contained" style={{
                            textTransform: 'initial',
                            border: '1px solid #000',
                            color: '#000',
                            borderRadius: '50px',
                            backgroundColor: '#FFF'
                        }}>
                            Entrar
                        </Button> 
                    </Link>
                </div>
            </Toolbar>
        </AppBar>
    </>
  );
}

export default Menu;
