import React, {useState} from 'react';
import { TextField, Grid, Typography, Button } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import image from './../assets/7849.jpg';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      weigth: '100vh',
      backgroundColor: '#FFFFFF'
    },
    title: {
        width: '70%', 
        color: 'black',
        '@media (max-width: 600px)': {
            width: '100%',
            textAlign: 'center',
            marginTop: '40px'
        }
    },
    text: {
        width: '70%', 
        color: 'gray',
        '@media (max-width: 600px)': {
            width: '100%',
            textAlign: 'center'
        }
    },
    input: {
        width: '70%',
        '@media (max-width: 600px)': {
            width: '100%'
        }
    },
    steps: {
        display: 'flex', 
        marginTop: '24px',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            justifyContent: 'center'
        }
    },
    inputCnpj: {
        width: '97%',
        '@media (max-width: 600px)': {
            width: '100%',
            marginBottom: '20px'
        }
    }
}));

function EmBreve () {

    const classes = useStyles();

    const [cnpj, setCnpj] = useState();
    const [step, setStep] = useState(1); 

    return (
        <>
            <div className={classes.root}>
                <div style={{width: '80%'}}>
                    <Grid container style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Grid md={6} xs={12}>
                            <img src={image} style={{width: '100%'}} />
                        </Grid>
                        <Grid md={6} xs={12}>
                            {step === 1 && (
                                <>
                                    <Typography variant="h6" className={classes.title}>
                                        Seja muito bem-vindo(a)!
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body1" className={classes.text}>
                                        Estamos finalizando algumas coisas para poder liberar o cadastro de sua empresa. <br></br> Deixe seu contato conosco para que possamos te avisar assim que tudo estiver pronto! :)
                                    </Typography>
                                    <br></br>
                                    <Grid container className={classes.input}>
                                        <Grid item md={12} xs={12}>
                                            <a style={{textDecoration: 'none'}} href="https://api.whatsapp.com/send?phone=5516992257677&text=Ol%C3%A1!%20Tenho%20interesse%20em%20utilizar%20a%20plataforma%20da%20GRYB%20e%20gostaria%20de%20deixar%20meu%20contato%20para%20me%20avisarem%20assim%20que%20a%20plataforma%20estiver%20liberada%20para%20uso." target="_blank">
                                                <Button disabled={false} variant="contained" style={{width: '100%', backgroundColor: '#06B4B8', color: '#FFFFFF', textTransform: 'capitalize', height: '40px', textTransform: 'initial'}}>Enviar contato!</Button>
                                            </a>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <Typography variant="h6" className={classes.title}>
                                        Confira os dados de sua empresa
                                    </Typography>
                                    <br></br>
                                    <InputMask
                                        mask="99.999.999/9999-99"
                                        onChange={(e) => {setCnpj(e.target.value);}}
                                        //onFocus={(e) => {}}
                                        value={cnpj}
                                        maskChar=""
                                        >
                                        {() => 
                                            <TextField id="outlined-basic" size="small" label="Razão Social" variant="outlined" style={{width: '100%'}} />
                                        }
                                    </InputMask>
                                    <br></br>
                                    <br></br>
                                    <InputMask
                                        mask="99.999.999/9999-99"
                                        onChange={(e) => {setCnpj(e.target.value);}}
                                        //onFocus={(e) => {}}
                                        value={cnpj}
                                        maskChar=""
                                        >
                                        {() => 
                                            <TextField id="outlined-basic" size="small" label="Capital Social" variant="outlined" style={{width: '100%'}} />
                                        }
                                    </InputMask>
                                    <br></br>
                                    <br></br>
                                    <Button onClick={() => {setStep(3)}} disabled={false} variant="contained" style={{width: '100%', backgroundColor: '#06B4B8', color: '#FFFFFF', textTransform: 'capitalize', height: '40px'}}>Avançar</Button>
                                </>
                            )}
                            {/*<div className={classes.steps}>
                                {step === 1 && (
                                    <>
                                        <div onClick={() => {setStep(1)}} style={{backgroundColor: 'black', borderRadius: '50%', height: '10px', width: '10px', cursor: 'pointer'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                    </>
                                )}
                                {step === 2 && (
                                    <>
                                        <div onClick={() => {setStep(1)}} style={{backgroundColor: 'black', borderRadius: '50%', height: '10px', width: '10px', cursor: 'pointer'}}></div>
                                        &nbsp; &nbsp;
                                        <div onClick={() => {setStep(2)}} style={{backgroundColor: 'black', borderRadius: '50%', height: '10px', width: '10px', cursor: 'pointer'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                    </>
                                )}
                                {step === 3 && (
                                    <>
                                        <div onClick={() => {setStep(1)}} style={{backgroundColor: 'black', borderRadius: '50%', height: '10px', width: '10px', cursor: 'pointer'}}></div>
                                        &nbsp; &nbsp;
                                        <div onClick={() => {setStep(2)}} style={{backgroundColor: 'black', borderRadius: '50%', height: '10px', width: '10px', cursor: 'pointer'}}></div>
                                        &nbsp; &nbsp;
                                        <div onClick={() => {setStep(3)}} style={{backgroundColor: 'black', borderRadius: '50%', height: '10px', width: '10px', cursor: 'pointer'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                        &nbsp; &nbsp;
                                        <div style={{backgroundColor: 'gray', borderRadius: '50%', height: '10px', width: '10px', cursor: 'default'}}></div>
                                    </>
                                )}
                            </div>*/}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );

}

export default EmBreve;