import React, {useState} from 'react';
import { TextField, Grid, Typography, Button, Paper, Modal, Fade, Backdrop, InputAdornment, IconButton } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import image from './../assets/7849.jpg';
import InputMask from 'react-input-mask';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Menu from '../components/menu.js';
import Rodape from '../components/rodape.js';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import qs from 'qs';
import CircularProgress from '@material-ui/core/CircularProgress';
import termos from './termos.pdf';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#FFFFFF'
    },
    title: {
        color: 'black',
        '@media (max-width: 600px)': {
            width: '100%',
            textAlign: 'center',
            marginTop: '40px'
        }
    },
    text: {
        color: 'gray',
        '@media (max-width: 600px)': {
            width: '100%',
            textAlign: 'center'
        }
    },
    textContinue: {
        color: 'gray',
        display: 'flex',
        paddingLeft: '20px',
        alignItems: 'center',
        cursor: 'pointer',
        '@media (max-width: 600px)': {
            width: '100%',
            textAlign: 'center'
        }
    },
    input: {
        width: '70%',
        '@media (max-width: 600px)': {
            width: '100%'
        }
    },
    steps: {
        display: 'flex', 
        marginTop: '24px',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            justifyContent: 'center'
        }
    },
    inputCnpj: {
        width: '97%',
        '@media (max-width: 600px)': {
            width: '100%',
            marginBottom: '20px'
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: '#FFFFFF',
        padding: '30px',
        borderRadius: '0px',
        border: 'none',
        width: '50%',
        height: 'auto',
        ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
            width: '90%',
        }
    },
    cpf_cnpj_div: {
        display: 'flex', 
        alignItems: 'center',
        ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
            display: 'block'
        }
    },
    cpf_cnpj_div_input: {
        width: '30%',
        ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%'
        }
    },
    titulo: {
        marginTop: '40px',
        ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: '20px'
        }
    },
    cpf_cnpj_div_titulo: {
        color: '#707072', 
        paddingLeft: '10px',
        ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
            paddingLeft: '0px',
            //textAlign: 'center',
            paddingTop: '10px'
        }
    },
    checkbox: {
        ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
            display: 'contents'
        }
    },
    registro: {
        position: 'relative', 
        marginTop: '50px', 
        width: '70%',
        ['@media (max-width: 780px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
        }
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Register () {

    const classes = useStyles();

    const [tipoCadastro, setTipoCadastro] = useState('cnpj');
    const [cnpj, setCnpj] = useState();
    const [cpf, setCpf] = useState();
    const [razaoSocial, setRazaoSocial] = useState();
    const [nomeFantasia, setNomeFantasia] = useState();
    const [nomeResponsavel, setNomeResponsavel] = useState();
    const [email, setEmail] = useState();
    const [step, setStep] = useState(1); 
    const [openModal, setOpenModal] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const [enderecoCep, setEnderecoCep] = useState();
    const [enderecoLagradouro, setEnderecoLagradouro] = useState();
    const [enderecoNumero, setEnderecoNumero] = useState();
    const [enderecoComplemento, setEnderecoComplemento] = useState();
    const [enderecoBairro, setEnderecoBairro] = useState();
    const [enderecoCidade, setEnderecoCidade] = useState();
    const [enderecoEstado, setEnderecoEstado] = useState();

    const [checkedPolitica, setCheckedPolitica] = useState(false);

    const [openSnack, setOpenSnack] = useState(false);
    const [messageSnack, setMessageSnack] = useState("");
    const [typeSnack, setTypeSnack] = useState("success");

    const formasConstituicao = [
        {title: 'EI - Empresário Individual'},
        {title: 'EIRELI - Empresa individual de resposabilidade limitada'},
        {title: 'LTDA - Sociedade empresarial de responsabilidade limitida'},
        {title: 'ME - Microempresa'},
        {title: 'MEI - Microempreendedor individual'},
        {title: 'SA - Sociedade anônima'},
        {title: 'SS - Sociedade simples'},
        {title: 'EIRELI - Empresa individual de resposabilidade limitada'}
    ];

    const cnaes = [
        { title: '0111-3/01 - Cultivo de arroz' },
        { title: '0111-3/02 - Cultivo de milho' },
        { title: '0111-3/03 - Cultivo de trigo' },
        { title: '0111-3/99 - Cultivo de outros cereais Não especificados anteriormente' },
        { title: '0112-1/01 - Cultivo de algodão herbáceo' },
        { title: '0112-1/02 - Cultivo de juta' },
        { title: '0112-1/99 - Cultivo de outras fibras de lavoura temporária Não especificadas anteriormente' },
        { title: '0113-0/00 - Cultivo de cana-de-açúcar' },
        { title: '0114-8/00 - Cultivo de fumo' }
    ];

    const handleGetEnderecoByCep = () => {

        axios.get('https://viacep.com.br/ws/'+enderecoCep+'/json/',{
            params: {},
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            console.log(res.data);
            setEnderecoLagradouro(res.data.logradouro);
            setEnderecoBairro(res.data.bairro);
            setEnderecoCidade(res.data.localidade);
            setEnderecoEstado(res.data.uf);
        })
        .catch(res => {})

    }

    const [loading, setLoading] = useState(false);

    const handleCadastro = () => {

        setLoading(true);

        if(tipoCadastro === "cnpj"){

            if(!cnpj || !razaoSocial || !nomeFantasia || !nomeResponsavel || !email || !enderecoCep || !enderecoLagradouro || !enderecoNumero || !enderecoBairro || !enderecoCidade || !enderecoEstado || !checkedPolitica){
                setLoading(false);
                setOpenSnack(true);
                setTypeSnack("error");
                setMessageSnack("Preencha todos os dados e confirme os termos.");
                return false;
            }

        }else if(tipoCadastro === "cpf"){

            if(!cpf || !nomeResponsavel || !nomeFantasia || !email || !enderecoCep || !enderecoLagradouro || !enderecoNumero || !enderecoBairro || !enderecoCidade || !enderecoEstado || !checkedPolitica){
                setLoading(false);
                setOpenSnack(true);
                setTypeSnack("error");
                setMessageSnack("Preencha todos os dados e confirme os termos.");
                return false;
            }

        }

        api.post('/auth/create', {
            cnpj: cnpj,
            razaoSocial: razaoSocial,
            nomeFantasia: nomeFantasia,
            nomeResponsavel: nomeResponsavel,
            cpfResponsavel: cpf,
            email: email,
            cep: enderecoCep,
            endereco: enderecoLagradouro,
            numero: enderecoNumero,
            bairro: enderecoBairro,
            complemento: enderecoComplemento,
            cidade: enderecoCidade,
            estado: enderecoEstado,
            tipo: tipoCadastro
        })
        .then(res => {

            if(res.data.status === "cadastrado"){
                setCnpj('');
                setCpf('');
                setRazaoSocial('');
                setNomeFantasia('');
                setNomeResponsavel('');
                setEmail('');
                setEnderecoCep('');
                setEnderecoLagradouro('');
                setEnderecoNumero('');
                setEnderecoBairro('');
                setEnderecoComplemento('');
                setEnderecoCidade('');
                setEnderecoEstado('');
                setLoading(false);
                setOpenSnack(true);
                setTypeSnack("success");
                setMessageSnack("Conta criada com sucesso! Verifique sua caixa de e-mail para confirmar a conta.");
                setCheckedPolitica(false);
            }

            if(res.data.status === "ja cadastrado"){
                setLoading(false);
                setOpenSnack(true);
                setTypeSnack("error");
                setMessageSnack("Empresa já cadastrada");
            }

        })
        .catch(err => {
            setLoading(false);
            setOpenSnack(true);
            setTypeSnack("error");
            setMessageSnack("Tivemos um erro ao cadastrar sua empresa. Tente novamente!");
        })

    }

    return (
        <>

            <Menu register={true} />

            <Container className={classes.registro}>

                <Grid container spacing={3}>
                    <Grid item md={12}>

                        <Typography variant="h4" className={classes.title}>
                            Crie sua conta!
                        </Typography>

                        <Typography variant="body1" className={classes.text}>
                            Vamos começar seu cadastro
                        </Typography>

                        <br></br>
                                
                        {tipoCadastro === 'cnpj' && (
                            <div className={classes.cpf_cnpj_div}>
                                <InputMask
                                    mask="99.999.999/9999-99"
                                    onChange={(e) => {setCnpj(e.target.value)}}
                                    value={cnpj}
                                    maskChar=""
                                    >
                                    {() => 
                                        <TextField id="outlined-basic" size="small" label="CNPJ da sua empresa" variant="outlined" className={classes.cpf_cnpj_div_input} />
                                    }
                                </InputMask>
                                <Typography variant="body2" className={classes.cpf_cnpj_div_titulo}>Ainda não tem CNPJ? Utilize o seu <span onClick={() => {setTipoCadastro('cpf')}} style={{color: '#06B4B8', cursor: 'pointer'}}>CPF</span></Typography>
                            </div>
                        )}
                        {tipoCadastro === 'cpf' && (
                           <div className={classes.cpf_cnpj_div}>
                                <InputMask
                                    mask="999.999.999-99"
                                    onChange={(e) => {setCpf(e.target.value)}}
                                    value={cpf}
                                    maskChar=""
                                    >
                                    {() => 
                                        <TextField id="outlined-basic" size="small" label="seu CPF" variant="outlined" className={classes.cpf_cnpj_div_input} />
                                    }
                                </InputMask>
                                <Typography variant="body2" style={{color: '#707072', paddingLeft: '10px'}}>Utilizar <span onClick={() => {setTipoCadastro('cnpj')}} style={{color: '#06B4B8', cursor: 'pointer'}}>CNPJ</span></Typography>
                            </div>
                        )}

                        <Typography variant="h6" className={classes.titulo}>
                            sobre
                        </Typography>

                        <br></br>

                        <Grid spacing={3} container>
                            {tipoCadastro === "cnpj" && (
                                <>
                                    <Grid item md={6} xs={12}>
                                        <TextField onChange={(e) => {setRazaoSocial(e.target.value)}} value={razaoSocial} id="outlined-basic" size="small" label="razão Social" variant="outlined" style={{width: '100%'}} />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField onChange={(e) => {setNomeFantasia(e.target.value)}} value={nomeFantasia} id="outlined-basic" size="small" label="nome fantasia" variant="outlined" style={{width: '100%'}} />
                                    </Grid>
                                </>
                            )}
                            {tipoCadastro === "cpf" && (
                                <>
                                    <Grid item md={12} xs={12}>
                                        <TextField onChange={(e) => {setNomeFantasia(e.target.value)}} value={nomeFantasia} id="outlined-basic" size="small" label="digite o nome da sua empresa" variant="outlined" style={{width: '100%'}} />
                                    </Grid>
                                </>
                            )}
                            <Grid item md={6} xs={12}>
                                <TextField onChange={(e) => {setNomeResponsavel(e.target.value)}} value={nomeResponsavel} id="outlined-basic" size="small" label="nome do responsável" variant="outlined" style={{width: '100%'}} />
                                <Typography variant="body2" style={{color: '#707072', paddingTop: '10px'}}>Pessoa responsável pelo acesso administrativo do sistema</Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField onChange={(e) => {setEmail(e.target.value)}} value={email} id="outlined-basic" size="small" label="e-mail" variant="outlined" style={{width: '100%'}} />
                            </Grid>
                        </Grid>

                        <Typography variant="h6" className={classes.titulo}>
                            endereço
                        </Typography>

                        <br></br>

                        <Grid container spacing={3}>
                            <Grid item md={3} xs={12}>
                                <InputMask
                                    mask="99999-999"
                                    onChange={(e) => {setEnderecoCep(e.target.value)}}
                                    value={enderecoCep}
                                    onBlur={handleGetEnderecoByCep}
                                    maskChar=""
                                    >
                                    {() => 
                                        <TextField id="outlined-basic" size="small" label="cep" variant="outlined" style={{width: '100%'}} />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <TextField InputLabelProps={{shrink: enderecoLagradouro ? true : false}} value={enderecoLagradouro} onChange={(e) => {setEnderecoLagradouro(e.target.value)}} id="outlined-basic" size="small" label="rua, Av, etc ..." variant="outlined" style={{width: '100%'}} />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <InputMask
                                    mask="9999999"
                                    onChange={(e) => {setEnderecoNumero(e.target.value)}}
                                    value={enderecoNumero}
                                    maskChar=""
                                >
                                    {() => 
                                        <TextField id="outlined-basic" size="small" label="número" variant="outlined" style={{width: '100%'}} />
                                    }
                                </InputMask>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField InputLabelProps={{shrink: enderecoBairro ? true : false}} value={enderecoBairro} onChange={(e) => {setEnderecoBairro(e.target.value)}} id="outlined-basic" size="small" label="bairro" variant="outlined" style={{width: '100%'}} />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField InputLabelProps={{shrink: enderecoComplemento ? true : false}} value={enderecoComplemento} onChange={(e) => {setEnderecoComplemento(e.target.value)}} id="outlined-basic" size="small" label="complemento" variant="outlined" style={{width: '100%'}} />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField InputLabelProps={{shrink: enderecoCidade ? true : false}} value={enderecoCidade} onChange={(e) => {setEnderecoCidade(e.target.value)}} id="outlined-basic" size="small" label="cidade" variant="outlined" style={{width: '100%'}} />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField InputLabelProps={{shrink: enderecoEstado ? true : false}} value={enderecoEstado} onChange={(e) => {setEnderecoEstado(e.target.value)}} id="outlined-basic" size="small" label="estado" variant="outlined" style={{width: '100%'}} />
                            </Grid>
                        </Grid>

                        <br></br>
                        <br></br>
                        <br></br>

                        <center>
                            <FormControlLabel
                                style={{ pointerEvents: "none" }}
                                value="end"
                                onChange={() => {checkedPolitica ? setCheckedPolitica(false) : setCheckedPolitica(true)}}
                                //checked={checkedPolitica ? true : false}
                                control={<Checkbox style={{ pointerEvents: "all" }} color="primary" />}
                                label={<Typography style={{ pointerEvents: "none" }}>li e aceito os <a href={termos} style={{textDecoration: 'none', pointerEvents: 'auto'}} target="_blank"><span style={{color: '#06B4B8', cursor: 'pointer'}}>termos e condições de uso</span></a></Typography>}
                                labelPlacement="end"
                                className={classes.checkbox}
                            />
                        </center>

                        <br></br>

                        <Grid container>
                            <Grid md={3} xs={0}></Grid>
                            <Grid md={6} xs={12}>
                                <Button onClick={handleCadastro} variant="contained" style={{backgroundColor: '#06B4B8', textTransform: 'none', color: '#FFF', width: '100%', height: '60px'}}>
                                    {loading ?
                                        <CircularProgress size={20} style={{color: '#FFFFFF'}} />
                                    :
                                        "criar minha conta"
                                    }
                                </Button>
                            </Grid>
                            <Grid md={3} xs={0}></Grid>
                        </Grid>

                        <br></br>
                        <br></br>

                    </Grid>
                </Grid>
                        
            </Container>

            {/*<Rodape />*/}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={() => {setOpenModal(false)}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal} style={{outline: 'none'}}>
                    <div className={classes.paper}>

                        <center>
                            <Typography variant="h6" className={classes.title}>
                                Que bom que voltou :)
                            </Typography>
                            <br></br>
                            <Typography variant="body1" className={classes.text}>
                                Informe seu e-mail de cadastro para verificarmos o que falta para completar seu cadastro.
                            </Typography>
                            <br></br>
                            <TextField id="outlined-basic" size="small" type="email" label="E-mail" variant="outlined" style={{width: '100%'}} />
                            <br></br>
                            <br></br>
                            <Button disabled={false} variant="contained" style={{backgroundColor: '#06B4B8', color: '#FFFFFF', textTransform: 'capitalize', height: '40px'}}><NavigateNextOutlinedIcon /> Continuar</Button>
                        </center>

                    </div>
                </Fade>
            </Modal>

            <Snackbar open={openSnack} autoHideDuration={6000} onClose={(e) => {setOpenSnack(false)}}>
                <Alert onClose={(e) => {setOpenSnack(false)}} severity={typeSnack}>
                {messageSnack}
                </Alert>
            </Snackbar>

        </>
    );

}

export default Register;