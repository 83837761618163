import React, {useState, useEffect} from 'react';
import { TextField, Grid, Typography, Button, Container } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import Menu from '../../components/menu.js';
import Rodape from '../../components/rodape.js';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';

const useStyles = makeStyles((theme) => ({
    root: {},
    conteudo: {
        minHeight: '80vh'
    }
}));

function Onboarding () {

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>

                <Menu />

                <Container className={classes.conteudo}>

                    <Typography variant="h6" style={{marginTop: '40px'}}>Onboarding</Typography>

                    <Typography variant="body2" style={{marginTop: '10px', color: '#707072'}}>Está com dúvida sobre como executar uma tarefa na plataforma?! Não se preocupe, basta procurar entre uma das opções abaixo :) </Typography>

                    <br></br>
                    <br></br>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                        <Typography className={classes.heading}>Como importar arquivos OFX</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            <ul>
                                <li>Passo 1: Clique em <b>Movimentações</b> no menu lateral.</li>
                                <li>Passo 2: Clique em <b>nova movimentação</b> no botão do lado superior direito.</li>
                                <li>Passo 3: No modal que se abrirá selecione o formato <b>Extrato Bancário (OFX).</b></li>
                                <li>Passo 4: Selecione o banco desejado, selecione seu arquivo e clique no botão "Upload Extrato".</li>
                            </ul>
                        </Typography>
                        </AccordionDetails>
                    </Accordion>

                </Container>

                <Rodape />

            </div>
        </>
    );

}

export default Onboarding;